.half {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem 0;
}

.half.light .typing {
  background-color: var(--chakra-colors-gray-300);
}

.typing {
  display: block;
  width: 60px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 8px;
}

.circle {
  display: block;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #8d8d8d;
  margin: 3px;
}

.circle.scaling {
  animation: typing 1000ms ease-in-out infinite;
  animation-delay: 3600ms;
}

.circle.bouncing {
  animation: bounce 1000ms ease-in-out infinite;
  animation-delay: 3600ms;
}

.circle:nth-child(1) {
  animation-delay: 0ms;
}

.circle:nth-child(2) {
  animation-delay: 333ms;
}

.circle:nth-child(3) {
  animation-delay: 666ms;
}

@keyframes typing {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0);
  }
}
